'use client'

//Libraries
import type * as Stitches from '@stitches/react'
import { MouseEventHandler, forwardRef, useImperativeHandle, useRef } from 'react'

import { CSS, keyframes, styled } from '../stitches.config'
//Utils
import { debounce } from '../utils/other-utils'

export interface ButtonProps {
  as?: 'button' | 'div' | 'a'
  /**
   * Give css class name to button.
   */
  className?: string

  /**
   * Give ID to button.
   */
  id?: string

  /**
   * This prop will be render inside button as icon, great way to add meaning full UI detail to button.
   */
  iconName?: string

  /**
   * This prop will be render inside button as icon, great way to add meaning full UI detail to button.
   */
  rightIconName?: string

  /**
   * Add label text to button. Ex : Hello World!.
   */
  label?: string

  /**
   * Get a callback when button is clicked.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>

  /**
   * Give css property to button component.
   */
  css?: CSS

  /**
   * Button disabled state.
   */

  disabled?: boolean

  /**
   * The stopPropagation is html event stopPropagation property flag. Read More at https://developer.mozilla.org/en-US/docs/Web/API/Event/stopPropagation.
   */
  stopPropagation?: boolean

  /**
   * This button's attributes.
   */
  attributes?: object

  /**
   * Button Text Color for special case.
   */
  textColor?: string

  /**
   * Possible state of the button. Default to 'default'
   */
  buttonState?: 'default' | 'waiting'

  /**
   * Type of the button.
   */
  buttonType?: 'button' | 'reset' | 'submit'
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps & Stitches.VariantProps<typeof Btn>>(
  (
    {
      className = '',
      id,
      fullWidth = false,
      iconName = '',
      rightIconName = '',
      label = '',
      onClick,
      buttonState = 'default',
      variant = 'primary',
      css = {},
      disabled,
      buttonType = 'button',
      as = 'button',
      ...props
    },
    forwardRef,
  ) => {
    // documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
    // Ref for the button
    const buttonRef = useRef<HTMLButtonElement>(null)
    // Ref for the button container (surround div to apppend span tags used for the ripple effect)
    const rippleContainerRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(forwardRef, () => buttonRef.current as HTMLButtonElement)

    /**
     * Adds ripple effect.
     * documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
     */
    const onAddRipple: MouseEventHandler<HTMLButtonElement> = e => {
      if (buttonRef && buttonRef.current && !disabled && buttonState !== 'waiting') {
        // following doc: https://medium.com/@jh3y/how-to-create-the-ripple-effect-from-google-material-design-c6f993e1d39
        const ripple = buttonRef.current
        const rippleContainer = rippleContainerRef.current
        if (ripple && rippleContainer) {
          const size = ripple.offsetWidth
          const pos = ripple.getBoundingClientRect()
          const rippler = document.createElement('span')
          const x = e.pageX - pos.left - size / 2
          const y = e.pageY - pos.top - size / 2
          const style = `top: ${y}px; left: ${x}px; height: ${size}px; width: ${size}px;`
          rippleContainer.appendChild(rippler)
          rippler.setAttribute('style', style)
        }
      }
    }

    /**
     * Removes the span dom element created by function onAddRipple to create the ripple effect
     * documentaiton: https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
     */
    const cleanUp = () => {
      const container = rippleContainerRef.current
      if (container && rippleContainerRef && rippleContainerRef.current && rippleContainerRef.current.firstChild) {
        while (rippleContainerRef.current.firstChild && container.firstChild) {
          container.removeChild(container.firstChild)
        }
      }
    }

    const onBtnClick: MouseEventHandler<HTMLButtonElement> = e => {
      if (disabled || buttonState === 'waiting') return
      if (onClick) onClick(e)
    }

    return (
      <Btn
        as={as}
        ref={buttonRef}
        className={className}
        css={css}
        disabled={disabled}
        fullWidth={fullWidth}
        onMouseDown={onAddRipple}
        onMouseUp={debounce(cleanUp, 2000)}
        state={buttonState}
        variant={variant}
        onClick={onBtnClick}
        type={buttonType}
        id={id}
        {...props}
      >
        {iconName && <span className="material-icons-outlined">{iconName}</span>}
        {(label || iconName) && <SpanStyled withSpace={!!iconName && !!label}>{label}</SpanStyled>}
        {rightIconName && (
          <span className="material-icons-outlined" style={{ marginLeft: label ? '12px' : '0px' }}>
            {rightIconName}
          </span>
        )}
        <span className="ripple--container" ref={rippleContainerRef} />
      </Btn>
    )
  },
)

Button.displayName = 'Button'

// Keyframes returns a unique name based on a hash of the contents of the keyframes
const rippleKeyframe = keyframes({
  to: {
    opacity: 0,
    transform: 'scale(2)',
  },
})

const loaderAnimation = keyframes({
  '0%': {
    animationTimingFunction: 'cubic-bezier(0.1909,0.4373,0.4509,0.7454)',
    transform: 'rotateX(0)',
  },
  '30%': {
    animationTimingFunction: 'cubic-bezier(0.128,0.2315,0.9704,0.8632)',
    transform: 'rotateX(153.72deg)',
  },
  '50%': {
    animationTimingFunction: 'cubic-bezier(0.5788,0.3001,0.5613,0.6784)',
    transform: 'rotateX(180deg)',
  },
  '55%': {
    animationTimingFunction: 'cubic-bezier(0.1545,0.4929,0.6089,0.9373)',
    transform: 'rotateX(238.68deg)',
  },
  '100%': {
    transform: 'rotateX(360deg)',
  },
})

// documentation on the css
// https://medium.com/@jh3y/how-to-create-the-ripple-effect-from-google-material-design-c6f993e1d39
const Btn = styled('button', {
  // Reset
  position: 'relative',
  overflow: 'hidden',
  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  // Custom
  cursor: 'pointer',
  fontFamily: '$button',
  fontWeight: '$button',
  fontStyle: '$button',
  textTransform: '$button',
  lineHeight: '$button',
  fontSize: '$button',
  letterSpacing: '$button',
  py: '$2',
  px: '$4',
  '&:after': {
    zIndex: -1,
    content: ' ',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '1em',
    height: '1em',
    margin: '-0.5em',
    animation: `${loaderAnimation} 1s infinite linear`,
    backgroundColor: '$gs1',
    display: 'inline-block',
    boxSizing: 'content-box',
    transformOrigin: '50%',
    transformBox: 'fill-box',
    borderRadius: '$round',
    transiton: 'all $fast',
    opacity: 0,
  },
  '& .ripple--container': {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    transform: 'translateY(0)',
    display: 'block',
  },
  '.ripple--container span': {
    transform: 'scale(0)',
    borderRadius: '100%',
    position: 'absolute',
    opacity: '0.75',
    backgroundColor: '$gs1',
    animation: `${rippleKeyframe} 1000ms`,
  },

  variants: {
    variant: {
      primary: {
        color: '$btnPriText',
        backgroundColor: '$btnPriBg',
        borderTopLeftRadius: '$btnPriTL',
        borderTopRightRadius: '$btnPriTR',
        borderBottomLeftRadius: '$btnPriBL',
        borderBottomRightRadius: '$btnPriBR',
        borderWidth: '$btnPri',
        borderColor: '$btnPriBo',
        borderStyle: '$btnPri',
        boxShadow:
          '0 4px 4px $colors$w11, 0 1px 2px $colors$w11, inset 0 6px 12px $colors$w11, inset 0 1px 1px $colors$w11',
        '&:hover': {
          backgroundColor: '$btnPriBg_L',
          transition: 'all $fast',
        },
        '&:active': {
          backgroundColor: '$btnPriBg_D',
          transition: 'all $fast',
        },
      },
      secondary: {
        color: '$btnSecText',
        backgroundColor: '$btnSecBg',
        borderTopLeftRadius: '$btnSecTL',
        borderTopRightRadius: '$btnSecTR',
        borderBottomLeftRadius: '$btnSecBL',
        borderBottomRightRadius: '$btnSecBR',
        borderWidth: '$btnSec',
        borderColor: '$btnSecBo',
        borderStyle: '$btnSec',
        boxShadow:
          '0 4px 4px $colors$w11, 0 1px 2px $colors$w11, inset 0 6px 12px $colors$w11, inset 0 1px 1px $colors$w11',
        '&:hover': {
          backgroundColor: '$btnSecBg_L',
          transition: 'all $fast',
        },
        '&:active': {
          backgroundColor: '$btnSecBg_D',
          transition: 'all $fast',
        },
      },
      tertiary: {
        color: '$btnTerText',
        backgroundColor: '$btnTerBg',
        borderTopLeftRadius: '$btnTerTL',
        borderTopRightRadius: '$btnTerTR',
        borderBottomLeftRadius: '$btnTerBL',
        borderBottomRightRadius: '$btnTerBR',
        borderWidth: '$btnTer',
        borderColor: '$btnTerBo',
        borderStyle: '$btnTer',
        // boxShadow:
        //   '0 4px 4px $colors$b11, 0 1px 2px $colors$b11, inset 0 6px 12px $colors$b11, inset 0 1px 1px $colors$b11',
        '&:hover': {
          backgroundColor: '$btnTerBg_L',
          transition: 'all $fast',
        },
        '&:active': {
          backgroundColor: '$btnTerBg_D',
          transition: 'all $fast',
        },
      },
      ghost: {
        color: '$btnGhoText',
        backgroundColor: '$btnGhoBg',
        borderTopLeftRadius: '$btnGhoTL',
        borderTopRightRadius: '$btnGhoTR',
        borderBottomLeftRadius: '$btnGhoBL',
        borderBottomRightRadius: '$btnGhoBR',
        borderWidth: '$btnGho',
        borderColor: '$btnGhoBo',
        borderStyle: '$btnGho',
        '&:hover': {
          backgroundColor: '$btnGhoBg_L',
          transition: 'all $fast',
        },
        '&:active': {
          backgroundColor: '$btnGhoBg_D',
          transition: 'all $fast',
        },
      },
    },
    state: {
      default: {},
      waiting: {
        cursor: 'initial',
        '&:after': {
          zIndex: '1',
          opacity: 1,
          transition: 'all $fast',
        },
      },
    },
    fullWidth: {
      true: { width: '100%' },
      false: { width: 'fit-content' },
    },
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      state: 'waiting',
      variant: 'primary',
      css: {
        '&:hover': {
          backgroundColor: '$btnPriBg',
          transition: 'all $fast',
        },
      },
    },
    {
      state: 'waiting',
      variant: 'secondary',
      css: {
        '&:hover': {
          backgroundColor: '$btnSecBg',
          transition: 'all $fast',
        },
      },
    },
    {
      state: 'waiting',
      variant: 'tertiary',
      css: {
        '&:hover': {
          backgroundColor: '$btnTerBg',
          transition: 'all $fast',
        },
      },
    },
    {
      disabled: true,
      variant: 'primary',
      css: {
        backgroundColor: '$gs8',
        color: '$gs9',
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$gs8',
          transition: 'all $fast',
          color: '$gs9',
        },
      },
    },
    {
      disabled: true,
      variant: 'secondary',
      css: {
        backgroundColor: '$gs8',
        color: '$gs9',
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$gs8',
          transition: 'all $fast',
          color: '$gs9',
        },
      },
    },
    {
      disabled: true,
      variant: 'ghost',
      css: {
        backgroundColor: '$none',
        color: '$gs8',
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$none',
          color: '$gs8',
          transition: 'all $fast',
        },
      },
    },
    {
      disabled: true,
      variant: 'tertiary',
      css: {
        backgroundColor: '$gs2',
        color: '$gs8',
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$gs2',
          color: '$gs8',
          transition: 'all $fast',
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'primary',
    state: 'default',
    fullWidth: false,
    disabled: false,
  },
})

const SpanStyled = styled('span', {
  whiteSpace: 'nowrap',

  variants: {
    withSpace: {
      true: {
        ml: '$2',
      },
      false: {
        ml: '$0',
      },
    },
  },
})
