import type * as Stitches from '@stitches/react'
import React from 'react'

import { CSS, styled } from '../stitches.config'
import { Box } from './Box'

export const GUTTERXSHORT = 24
export const GUTTERXSHORTBP1 = 32
export const GUTTERXSHORTBP3 = 40
export const CONTENT_MAX_WIDTH = 1200

const StyledGutter = styled('div', {
  margin: '0 auto',
  height: '100%',
  boxSizing: 'border-box',
  '@bp3': {
    maxWidth: `${CONTENT_MAX_WIDTH}px`,
  },

  variants: {
    variant: {
      short: {
        px: '$6',
        py: '$2',
        '@bp1': {
          px: '$8',
          py: '$2',
        },
        '@bp3': {
          px: '$10',
          py: '$6',
        },
      },
      tall: {
        p: '$6',
        '@bp1': {
          p: '$8',
        },
        '@bp3': {
          p: '$10',
        },
      },
      bare: {
        p: '$0',
      },
      horizontal: {
        px: '$6',
        '@bp1': {
          px: '$8',
        },
        '@bp3': {
          px: '$10',
        },
      },
    },
    modificiations: {
      noTopPadding: {
        paddingTop: '0 !important',
      },
    },
  },
  defaultVariants: {
    variant: 'tall',
  },
})

type CSSProp = { css?: CSS }
type GutterProps = {
  backgroundColor?: CSS['backgroundColor']
}

type OwnProps = GutterProps & CSSProp & React.HTMLProps<HTMLDivElement> & Stitches.VariantProps<typeof StyledGutter>

export const Gutter = React.forwardRef<HTMLDivElement, OwnProps>((props, forwardedRef) => {
  const { children, css, backgroundColor, variant, modificiations, ...otherProps } = props
  return (
    <Box {...otherProps} css={{ backgroundColor, ...css }} ref={forwardedRef}>
      <StyledGutter variant={variant} modificiations={modificiations}>
        {children}
      </StyledGutter>
    </Box>
  )
})

Gutter.displayName = 'Gutter'
