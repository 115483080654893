'use client'

import Link from 'next/link'
import { styled } from 'pattern-library/src/stitches.config'

import { Button } from '../basicUI/Button'
import { Flex } from '../basicUI/Flex'
import { Gutter } from '../basicUI/Gutter'
import { Text } from '../basicUI/Text'

export const NotFound = () => {
  // Inspiration: https://codepen.io/tripti1410/pen/jOBEYXX
  return (
    <Container>
      <Flex align="center" direction={'column'} justify={'center'}>
        <Gutter>
          <Flex align="center" direction={'column'} justify={'center'} gap={5}>
            <Text variant="h1">404</Text>
            <Text variant="subtitle1">You didn't break the internet, but we can't find what you are looking for.</Text>
            <Link href="/" style={{ textDecoration: 'none' }}>
              <Button label="Go Back" />
            </Link>
          </Flex>
        </Gutter>
      </Flex>
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  aligItems: 'center',
  textAlign: 'center',
  minHeight: '100dvh',
  minWidth: '100dvw',
})
